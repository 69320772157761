/**
 * @owner @Appboy/reporting
 *
 * In order to sync up and share KPI string constants in the frontend and backend,
 * this translation file is typed using the constants in report-builder-constants.json.erb
 *
 * To add a new KPI:
 * 1. Add the constant to report-builder-constants.json.erb, underneath KPIs
 * 2. Run yarn shared:regenerate in /platform
 * 3. Add the translation to this file
 */
import type { I18nT } from "@lib/dashboardI18n";
import type sharedConstants from "@lib/shared_constants.json";

export type KpisType =
  (typeof sharedConstants.REPORT_BUILDER.KPIS)[keyof typeof sharedConstants.REPORT_BUILDER.KPIS];
export type KpisObjectType = Record<KpisType, string> & // The right way to add new KPI constants. Follow the instructions above
  Record<string, string>; // Untyped strings is deprecated, but needed for backwards compatibility

const kpiLabelsGetter = (t: I18nT<"kpi-labels">): KpisObjectType => ({
  bodyClickRate: t("body-click-rate", "Body Click Rate"),
  bodyClicks: t("body-clicks", "Body Clicks"),
  bounces: t("bounces", "Bounces"),
  bounceRate: t("bounce-rate", "Bounce Rate"),
  firstButtonClicks: t("button-one-click", "Button 1 Clicks"),
  firstButtonClickRate: t("button-one-click-rate", "Button 1 Click Rate"),
  secondButtonClicks: t("button-two-click", "Button 2 Clicks"),
  secondButtonClickRate: t("button-two-click-rate", "Button 2 Click Rate"),
  clickToOpenRate: t("click-to-open-rate", "Click-to-Open Rate"),
  clickedShortenedSmsLink: t("clicked-shortened-sms-link", "Clicked Shortened SMS Link"),
  confirmedDeliveries: t("confirmed-deliveries", "Confirmed Deliveries"),
  confirmedDeliveryRate: t("confirmed-delivery-rate", "Confirmed Delivery Rate"),
  conversionConfidence: t("conversion-confidence", "Confidence (%)"),
  conversionRate: t("primary-conversion-event-a-rate", "Primary Conversion Event A Rate"),
  conversionRateB: t("conversion-event-b-rate", "Conversion Event B Rate"),
  conversionRateC: t("conversion-event-c-rate", "Conversion Event C Rate"),
  conversionRateD: t("conversion-event-d-rate", "Conversion Event D Rate"),
  conversionMeta: t("primary-conversion-event-name", "Primary Conversion Event Name"),
  conversionMetaB: t("conversion-event-b-name", "Conversion Event B Name"),
  conversionMetaC: t("conversion-event-c-name", "Conversion Event C Name"),
  conversionMetaD: t("conversion-event-d-name", "Conversion Event D Name"),
  deliveries: t("deliveries", "Deliveries"),
  deliveryRate: t("delivery-rate", "Delivery Rate"),
  directOpens: t("direct-opens", "Direct Opens"),
  directOpenRate: t("direct-open-rate", "Direct Open Rate"),
  emailSends: t("email-sends", "Sends"),
  errors: t("errors", "Errors"),
  errorRate: t("error-rate", "Error Rate"),
  failedDeliveries: t("failed-deliveries", "Failed Deliveries"),
  failedDeliveryRate: t("failed-delivery-rate", "Failed Delivery Rate"),
  failures: t("failures", "Failures"),
  failureRate: t("failure-rate", "Failure Rate"),
  influencedOpens: t("influenced-opens", "Influenced Opens"),
  influencedOpenRate: t("influenced-open-rate", "Influenced Open Rate"),
  percentChangeFromControl: t("change-from-control", "Change from Control"),
  totalConversions: t("primary-conversion-event-a", "Primary Conversion Event A"),
  totalConversionsB: t("conversion-event-b", "Conversion Event B"),
  totalConversionsC: t("conversion-event-c", "Conversion Event C"),
  totalConversionsD: t("conversion-event-d", "Conversion Event D"),
  reads: t("reads", "Reads"),
  readRate: t("read-rate", "Read Rate"),
  rejections: t("rejections", "Rejections"),
  rejectionRate: t("rejection-rate", "Rejection Rate"),
  subjectLine: t("subject-line", "Subject Line"),
  sends: t("sends", "Sends"),
  sendsToCarrier: t("sends-to-carrier", "Sends to Carrier"),
  sendsToCarrierRate: t("sends-to-carrier-rate", "Sends to Carrier Rate"),
  optIn: t("opt-in", "Opt In"),
  optOut: t("opt-out", "Opt Out"),
  spamReports: t("spam", "Spam"),
  spamReportRate: t("spam-rate", "Spam Rate"),
  totalClicks: t("total-clicks", "Total Clicks"),
  totalClickRate: t("total-click-rate", "Total Click Rate"),
  totalIndirectRevenue: t("total-indirect-revenue", "Total Indirect Revenue"),
  totalCanvasSends: t("total-canvas-sends", "Sends"),
  totalDirectRevenue: t("total-direct-revenue", "Total Direct Revenue"),
  totalDirectPurchases: t("total-direct-purchases", "Total Direct Purchases"),
  totalImpressions: t("total-impressions", "Total Impressions"),
  directRevenuePerRecipient: t("revenue-per-recipient", "Revenue per Recipient"),
  totalDismissals: t("total-dismissals", "Total Dismissals"),
  totalDismissalRate: t("total-dismissal-rate", "Total Dismissal Rate"),
  machineOpens: t("machine-opens", "Machine Opens"),
  otherOpens: t("other-opens", "Other Opens"),
  totalOpens: t("total-opens", "Total Opens"),
  totalOpenRate: t("total-open-rate", "Total Open Rate"),
  totalRevenue: t("total-revenue", "Total Revenue"),
  totalSends: t("messages-sent", "Messages Sent"),
  variantCount: t("number-of-variants", "Number of Variants"),
  stepCount: t("number-of-steps", "Number of Steps"),
  totalEntries: t("entries", "Entries"),
  uniqueClicks: t("unique-clicks", "Unique Clicks"),
  uniqueClickRate: t("unique-click-rate", "Unique Click Rate"),
  uniqueDirectPurchases: t("unique-direct-purchases", "Unique Direct Purchases"),
  uniqueDismissals: t("unique-dismissals", "Unique Dismissals"),
  uniqueDismissalRate: t("unique-dismissal-rate", "Unique Dismissal Rate"),
  uniqueImpressions: t("unique-impressions", "Unique Impressions"),
  uniqueOpens: t("unique-opens", "Unique Opens"),
  uniqueOpenRate: t("unique-open-rate", "Unique Open Rate"),
  unsubscribes: t("unsubscribes", "Unsubscribes"),
  unsubscribeRate: t("unsubscribe-rate", "Unsubscribe Rate"),
  uniqueRecipients: t("unique-recipients", "Unique Recipients"),
  "contentCard.sends": t("send-content-cards", "Sends (Content Cards)"),
  "contentCard.totalClicks": t("total-clicks-content-cards", "Total Clicks (Content Cards)"),
  "contentCard.totalClickRate": t("total-click-rate-content-cards", "Total Click Rate (Content Cards)"),
  "contentCard.totalDismissals": t("total-dismissals-content-cards", "Total Dismissals (Content Cards)"),
  "contentCard.totalDismissalRate": t("total-dismissal-rate-content-cards", "Total Dismissal Rate (Content Cards)"),
  "contentCard.totalImpressions": t("total-impressions-content-cards", "Total Impressions (Content Cards)"),
  "contentCard.uniqueClicks": t("unique-clicks-content-cards", "Unique Clicks (Content Cards)"),
  "contentCard.uniqueClickRate": t("unique-click-rate-content-cards", "Unique Click Rate (Content Cards)"),
  "contentCard.uniqueDismissals": t("unique-dismissals-content-cards", "Unique Dismissals (Content Cards)"),
  "contentCard.uniqueDismissalRate": t(
    "unique-dismissal-rate-content-cards",
    "Unique Dismissal Rate (Content Cards)"
  ),
  "contentCard.uniqueImpressions": t("unique-impressions-content-cards", "Unique Impressions (Content Cards)"),
  "sms.confirmedDeliveries": t("sms-confirmed-deliveries", "Confirmed Deliveries (SMS)"),
  "sms.confirmedDeliveryRate": t("sms-confirmed-delivery-rate", "Confirmed Delivery Rate (SMS)"),
  "sms.failedDeliveries": t("sms-failed-deliveries", "Failed Deliveries (SMS)"),
  "sms.failedDeliveryRate": t("sms-failed-delivery-rate", "Failed Delivery Rate (SMS)"),
  "sms.rejections": t("sms-rejections", "Rejections (SMS)"),
  "sms.rejectionRate": t("sms-rejection-rate", "Rejection Rate (SMS)"),
  "sms.sends": t("sms-sends", "Sends (SMS)"),
  "sms.sendsToCarrier": t("sms-sends-to-carrier", "Sends to Carrier (SMS)"),
  "sms.sendsToCarrierRate": t("sms-sends-to-carrier-rate", "Sends to Carrier Rate (SMS)"),
  "sms.totalClicks": t("sms-total-clicks", "Total Clicks (SMS)"),
  "sms.totalClickRate": t("sms-total-click-rate", "Total Click Rate (SMS)"),
  "sms.uniqueRecipients": t("sms-unique-recipients", "Unique Recipients (SMS)"),
  "whatsApp.deliveries": t("whats-app-deliveries", "Deliveries (WhatsApp)"),
  "whatsApp.deliveryRate": t("whats-app-delivery-rate", "Delivery Rate (WhatsApp)"),
  "whatsApp.failures": t("whats-app-failures", "Failures (WhatsApp)"),
  "whatsApp.failureRate": t("whats-app-failure-rate", "Failure Rate (WhatsApp)"),
  "whatsApp.reads": t("whats-app-read", "Reads (WhatsApp)"),
  "whatsApp.readRate": t("whats-app-read-rate", "Read Rate (WhatsApp)"),
  "whatsApp.sends": t("whats-app-sends", "Sends (WhatsApp)"),
  "whatsApp.uniqueRecipients": t("whats-app-unique-recipients", "Unique Recipients (WhatsApp)"),
  "email.bounces": t("email-bounces", "Bounces (Email)"),
  "email.bounceRate": t("email-bounce-rate", "Bounce Rate (Email)"),
  "email.clickToOpenRate": t("email-click-to-open-rate", "Click to Open Rate (Email)"),
  "email.deliveries": t("email-deliveries", "Deliveries (Email)"),
  "email.deliveryRate": t("email-delivery-rate", "Delivery Rate (Email)"),
  "email.sends": t("email-type-sends", "Sends (Email)"),
  "email.subjectLine": t("email-subject-line", "Subject Line (Email)"),
  "email.emailSendRate": t("email-send-rate", "Email Send Rate (Email)"),
  "email.spamReports": t("email-spam-reports", "Spam Reports (Email)"),
  "email.spamReportRate": t("email-spam-report-rate", "Spam Report Rate (Email)"),
  "email.totalClicks": t("email-total-clicks", "Total Clicks (Email)"),
  "email.totalClickRate": t("email-total-click-rate", "Total Click Rate (Email)"),
  "email.totalOpens": t("email-total-opens", "Total Opens (Email)"),
  "email.otherOpens": t("email-other-opens", "Other Opens (Email)"),
  "email.machineOpen": t("email-machine-open", "Machine Opens (Email)"),
  "email.totalOpenRate": t("email-total-open-rate", "Total Open Rate (Email)"),
  "email.uniqueClicks": t("email-unique-clicks", "Unique Clicks (Email)"),
  "email.uniqueClickRate": t("email-unique-click-rate", "Unique Click Rate (Email)"),
  "email.uniqueOpens": t("email-unique-opens", "Unique Opens (Email)"),
  "email.uniqueOpenRate": t("email-unique-open-rate", "Unique Open Rate (Email)"),
  "email.uniqueRecipients": t("email-unique-recipients", "Unique Recipients (Email)"),
  "email.unsubscribes": t("email-unsubscribes", "Unsubscribes (Email)"),
  "email.unsubscribeRate": t("email-unsubscribe-rate", "Unsubscribe Rate (Email)"),
  "androidPush.bounces": t("bounces-android-push", "Bounces (Android Push)"),
  "androidPush.bounceRate": t("bounce-rate-android-push", "Bounce Rate (Android Push)"),
  "androidPush.deliveries": t("deliveries-android-push", "Deliveries (Android Push)"),
  "androidPush.deliveryRate": t("delivery-rate-android-push", "Delivery Rate (Android Push)"),
  "androidPush.directOpens": t("direct-opens-android-push", "Direct Opens (Android Push)"),
  "androidPush.directOpenRate": t("direct-open-rate-android-push", "Direct Open Rate (Android Push)"),
  "androidPush.influencedOpens": t("influenced-opens-android-push", "Influenced Opens (Android Push)"),
  "androidPush.influencedOpenRate": t("influenced-open-rate-android-push", "Influenced Open Rate (Android Push)"),
  "androidPush.sends": t("sends-android-push", "Sends (Android Push)"),
  "androidPush.totalOpens": t("total-opens-android-push", "Total Opens (Android Push)"),
  "androidPush.totalOpenRate": t("total-open-rate-android-push", "Total Open Rate (Android Push)"),
  "androidPush.uniqueRecipients": t("unique-recipients-android-push", "Unique Recipients (Android Push)"),
  "allMobilePush.bounces": t("bounces-combined-push", "Bounces"),
  "allMobilePush.bounceRate": t("bounce-rate-combined-push", "Bounce Rate"),
  "allMobilePush.deliveries": t("deliveries-combined-push", "Deliveries"),
  "allMobilePush.deliveryRate": t("delivery-rate-combined-push", "Delivery Rate"),
  "allMobilePush.directOpens": t("direct-opens-combined-push", "Direct Opens"),
  "allMobilePush.directOpenRate": t("direct-open-rate-combined-push", "Direct Open Rate"),
  "allMobilePush.influencedOpens": t("influenced-opens-combined-push", "Influenced Opens"),
  "allMobilePush.influencedOpenRate": t("influenced-open-rate-combined-push", "Influenced Open Rate"),
  "allMobilePush.sends": t("sends-combined-push", "Sends"),
  "allMobilePush.totalOpens": t("total-opens-combined-push", "Total Opens"),
  "allMobilePush.totalOpenRate": t("total-open-rate-combined-push", "Total Open Rate"),
  "allMobilePush.uniqueRecipients": t("unique-recipients-combined-push", "Unique Recipients"),
  "iosPush.bounces": t("bounces-ios-push", "Bounces (iOS Push)"),
  "iosPush.bounceRate": t("bounce-rate-ios-push", "Bounce Rate (iOS Push)"),
  "iosPush.deliveries": t("deliveries-ios-push", "Deliveries (iOS Push)"),
  "iosPush.deliveryRate": t("delivery-rate-ios-push", "Delivery Rate (iOS Push)"),
  "iosPush.directOpens": t("direct-opens-ios-push", "Direct Opens (iOS Push)"),
  "iosPush.directOpenRate": t("direct-open-rate-ios-push", "Direct Open Rate (iOS Push)"),
  "iosPush.influencedOpens": t("influenced-opens-ios-push", "Influenced Opens (iOS Push)"),
  "iosPush.influencedOpenRate": t("influenced-open-rate-ios-push", "Influenced Open Rate (iOS Push)"),
  "iosPush.sends": t("sends-ios-push", "Sends (iOS Push)"),
  "iosPush.totalOpens": t("total-opens-ios-push", "Total Opens (iOS Push)"),
  "iosPush.totalOpenRate": t("total-open-rate-ios-push", "Total Open Rate (iOS Push)"),
  "iosPush.uniqueRecipients": t("unique-recipients-ios-push", "Unique Recipients (iOS Push)"),
  "kindlePush.bounces": t("bounces-kindle-push", "Bounces (Kindle Push)"),
  "kindlePush.bounceRate": t("bounce-rate-kindle-push", "Bounce Rate (Kindle Push)"),
  "kindlePush.deliveries": t("deliveries-kindle-push", "Deliveries (Kindle Push)"),
  "kindlePush.deliveryRate": t("delivery-rate-kindle-push", "Delivery Rate (Kindle Push)"),
  "kindlePush.directOpens": t("direct-opens-kindle-push", "Direct Opens (Kindle Push)"),
  "kindlePush.directOpenRate": t("direct-open-rate-kindle-push", "Direct Open Rate (Kindle Push)"),
  "kindlePush.influencedOpens": t("influenced-opens-kindle-push", "Influenced Opens (Kindle Push)"),
  "kindlePush.influencedOpenRate": t("influenced-open-rate-kindle-push", "Influenced Open Rate (Kindle Push)"),
  "kindlePush.sends": t("sends-kindle-push", "Sends (Kindle Push)"),
  "kindlePush.totalOpens": t("total-opens-kindle-push", "Total Opens (Kindle Push)"),
  "kindlePush.totalOpenRate": t("total-open-rate-kindle-push", "Total Open Rate (Kindle Push)"),
  "kindlePush.uniqueRecipients": t("unique-recipients-kindle-push", "Unique Recipients (Kindle Push)"),
  "webPush.bounces": t("bounces-web-push", "Bounces (Web Push)"),
  "webPush.bounceRate": t("bounce-rate-web-push", "Bounce Rate (Web Push)"),
  "webPush.deliveries": t("deliveries-web-push", "Deliveries (Web Push)"),
  "webPush.deliveryRate": t("delivery-rate-web-push", "Delivery Rate (Web Push)"),
  "webPush.sends": t("sends-web-push", "Sends (Web Push)"),
  "webPush.totalOpens": t("total-opens-web-push", "Total Opens (Web Push)"),
  "webPush.totalOpenRate": t("total-open-rate-web-push", "Total Open Rate (Web Push)"),
  "webPush.uniqueRecipients": t("unique-recipients-web-push", "Unique Recipients (Web Push)"),
  "trigger.bodyClicks": t("trigger-iam-body-clicks", "Body Clicks (In App Message)"),
  "trigger.bodyClickRate": t("trigger-iam-body-click-rate", "Body Click Rate (In App Message)"),
  "trigger.firstButtonClicks": t("trigger-iam-button-one-clicks", "Button 1 Clicks (In App Message)"),
  "trigger.firstButtonClickRate": t("trigger-iam-button-one-click-rate", "Button 1 Click Rate (In App Message)"),
  "trigger.secondButtonClicks": t("trigger-iam-button-two-clicks", "Button 2 Clicks (In App Message)"),
  "trigger.secondButtonClickRate": t("trigger-iam-button-two-click-rate", "Button 2 Click Rate (In App Message)"),
  "trigger.totalImpressions": t("trigger-iam-total-impressions", "Total Impressions (In App Message)"),
  "trigger.uniqueImpressions": t("trigger-iam-unique-impressions", "Unique Impressions (In App Message)"),
  "webhook.sends": t("sends-webhook", "Sends (Web Hook)"),
  "webhook.errors": t("errors-webhook", "Errors (Web Hook)"),
  "webhook.errorRate": t("error-rate-webhook", "Error Rate (Web Hook)"),
  "webhook.uniqueRecipients": t("unique-recipients-webhook", "Unique Recipients (Web Hook)"),
});

export default kpiLabelsGetter;
