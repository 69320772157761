/**
 * @owner @Appboy/channels
 */

import type { I18nT } from "@lib/dashboardI18n";

const channelLabelsGetter = (t: I18nT<"channel-labels">) => ({
  "Content Cards": t("content-cards-stats-group", "Content Cards"),
  "In-App Message": t("iam-stats-group", "In-App Messages"),
  "SMS/MMS": t("sms-mms-stats-group", "SMS/MMS"),
  Email: t("email-stats-group", "Email"),
  Push: t("push-stats-group", "Push"),
  SMS: t("sms-stats-group", "SMS/MMS/RCS"),
  "SMS/MMS/RCS": t("sms-mms-rcs-stats-group", "SMS/MMS/RCS"),
  Webhooks: t("webhooks-stats-group", "Webhooks"),
  "Android Push": t("android-push-group", "Android Push"),
  "iOS Push": t("ios-push", "iOS Push"),
  "Kindle Push": t("kindle-push", "Kindle Push"),
  Webhook: t("webhook", "Webhook"),
  "Web Push": t("web-push", "Web Push"),
  Slideup: t("slide-up", "Original In-App Message"),
  "Original In-App Message": t("slide-up", "Original In-App Message"),
  Control: t("control", "Control"),
  "users-local-time": t("users-local-time", "user's local time"),
  WhatsApp: t("whatsapp", "WhatsApp"),
  "Feature Flag Experiments": t("feature-flag-experiments", "Feature Flag Experiments"),
  LINE: t("line", "LINE"),
  "Banner Cards": t("banner-cards", "Banner Cards"),
});

export default channelLabelsGetter;
