/**
 * @owner @Appboy/sms
 */

import type { I18nT } from "@lib/dashboardI18n";

export type SMSKeywordLabelsTrans = I18nT<"sms-subscription-group-keyword-labels">;

const smsKeywordLabelsGetter = (t: I18nT<"sms-subscription-group-keyword-labels">) => {
  return {
    "Description of a Keyword Category Tooltip": t(
      "category-tooltip",
      "Keyword Category represents a set of keywords that will trigger a response to a user. You can also use it to retarget users who responded with a keyword in the category."
    ),
    "Description of Opt-In Tooltip": t(
      "opt-in-tooltip",
      "When users send these keywords to this Subscription Group, they will be added to the Subscription Group and will receive a confirmation opt-in message, which you can customize."
    ),
    "Description of Opt-Out Tooltip": t(
      "opt-out-tooltip",
      "When users send these keywords to this Subscription Group, they will be removed from the Subscription Group and will receive a confirmation opt-out message, which you can customize."
    ),
    "Description of Help Tooltip": t(
      "help-tooltip",
      "When users send these keywords to this Subscription Group, they will receive an automated help message, which you can customize."
    ),
    "Keyword Category": t("keyword-category-header", "Keyword Category"),
    "Reply Message": t("reply-message-header", "Reply Message"),
    "Fuzzy Auto Response": t("fuzzy-auto-response-header", "Fuzzy Auto Response"),
    "Fuzzy Opt-Out": t("fuzzy-opt-out", "Fuzzy Opt-Out"),
    "Description of Fuzzy Opt-Out": t(
      "fuzzy-opt-out-description",
      "Intelligent processing that handles misspelled message responses that represent opt-out intent"
    ),
    Keywords: t("keywords-header", "Keywords"),
    "Delete Language Text": t(
      "delete-language-body",
      "All keyword and message configurations for this language will be lost. Are you sure you want to delete this language?"
    ),
    "Delete Category Text": t(
      "delete-category-body",
      "The custom keyword category for this language will be deleted. Are you sure you want to delete this category?"
    ),
    "Add a Language": t("add-a-language", "Add a Language"),
    "Learn More": t("learn-more", "Learn More"),
    "Disclaimer Text for SMS Use": t(
      "alert-disclaimer-text",
      "The use of SMS is subject to specific legal requirements. To ensure that you are using the SMS Services in compliance with all applicable laws, you should seek the advice of your legal counsel."
    ),
    "Alpha Codes Warning Title": t(
      "alpha-codes-warning-title",
      "Inbound Keyword Processing is not available for Subscription Groups with only Alphanumeric Sender IDs due to two-way messaging not being supported"
    ),
    Required: t("required", "Required"),
    "Required Parentheses": t("required-parentheses", "(Required)"),
    "Opt-In": t("opt-in", "Opt-In"),
    "Opt-Out": t("opt-out", "Opt-Out"),
    Help: t("help", "Help"),
    Custom: t("custom", "Custom"),
    "No message": t("no-message", "No message"),
    "No keywords": t("no-keywords", "No keywords"),
    "Body Text for Deleting Category": t(
      "delete-category-body",
      "The custom keyword category for this language will be deleted. Are you sure you want to delete this category?"
    ),
    "Body Text for Deleting Language": t(
      "delete-language-body",
      "All keyword and message configurations for this language will be lost. Are you sure you want to delete this language?"
    ),
    "Read Only Permission for Developer": t(
      "alert-no-edit",
      "Based on your current developer permissions, your access to Global Keywords is limited to read-only."
    ),
    Message: t("message", "Message"),
    "Add custom keyword": t("add-custom-keyword", "Add custom keyword"),
    "Global Keywords": t("global-keywords", "Global Keywords"),
    Name: t("name", "Name"),
    ID: t("id", "ID"),
    "Link Shortening Domain": t("link-shortening-domain", "Link Shortening Domain"),
    "Subscription Group Description": t("subscription-group-description", "Subscription Group Description"),
    "Subscription Group Details": t("subscription-group-details", "Subscription Group Details"),
    "Saved changes to Subscription Group.": t("saved-subscription-group", "Saved changes to Subscription Group."),
    "Opt-In Method": t("opt-in-method", "Opt-In Method"),
    "Opt-In Help Text": t(
      "opt-in-help-text",
      'User subscription status defaults to "subscribed" after intent is expressed'
    ),
    "Double Opt-In": t("double-opt-in", "Double Opt-In"),
    "Double Opt-In Help Text": t(
      "double-opt-in-help-text",
      'Subscription status is updated to "subscribed" only after users reply to a text message opt-in prompt'
    ),
  };
};

export type SMSKeywordLabelsOptions = keyof ReturnType<typeof smsKeywordLabelsGetter>;

export default smsKeywordLabelsGetter;
