/**
 * @owner @Appboy/messaging-and-automation
 */

import type { I18nT } from "@lib/dashboardI18n";

const campaignTypeLabelsGetter = (t: I18nT<"campaign-type-labels">) => ({
  multi: t("campaign-type-filter-multichannel", "Multichannel"),
  email: t("campaign-type-filter-email", "Email"),
  push: t("campaign-type-filter-push", "Push notification"),
  in_app_message: t("campaign-type-in-app-message", "In-app message"),
  content_card: t("campaign-type-content-card", "Content Card"),
  banner: t("campaign-type-banner", "Banner Card"),
  sms_mms: t("campaign-type-sms-mms", "SMS/MMS/RCS"),
  sms: t("campaign-type-sms", "SMS"),
  webhook: t("campaign-type-webhook", "Webhook"),
  whatsapp: t("campaign-type-whatsapp", "WhatsApp"),
  line: t("campaign-type-line", "LINE"),
  feature_flag_experiment: t("campaign-type-feature-flags-experiment", "Feature flag experiment"),
  api: t("campaign-type-api", "API campaign"),
  transactional: t("campaign-type-transactional", "Transactional email"),
});

export default campaignTypeLabelsGetter;
